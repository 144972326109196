/* eslint-disable */
import 'intersection-observer';
import 'url-search-params-polyfill';
import { isEmpty } from 'lodash';

import { RECEIVE_PRODUCTS } from 'app/products/components/productList/productListConstants';
import { DETAILS_LOADED } from 'app/orderPlacement/productDetailsPage/productDetailsPageConstants';
import { STATUS_CODES } from 'app/commonComponents/addToBag/addToBagConstants';
import { SET_CHECKOUT_CART_DATA } from 'app/checkout/checkoutConstants';

let items = [];

document.addEventListener(STATUS_CODES.ADDED_TO_BAG, (e) => {

  const action = e.detail.reduxAction;
  let data = action.data;

  const category = data.entry.product.url.split('/')[2] || '';

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      items: [{
        item_name: data.entry.product.name,
        item_id: data.entry.product.baseProduct,
        item_category: category,
        index: data.entry.entryNumber,
        quantity: data.quantity,
        price: data.entry.product.price.value
      }]
    }
  });
});


document.addEventListener(RECEIVE_PRODUCTS, (e) => {

  const action = e.detail.reduxAction;

  items = action.products.map((product, i) => {
    const category = product.url.split('/')[4] || '';

    const productData = {
      item_name: product.name,
      item_id: product.id,
      item_category: category,
      index: i,
      quantity: 1,
      price: product.price.value
    };

    return productData;
  });

  if(items.length > 0) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        items: items
      }
    });
  }
});


document.addEventListener(SET_CHECKOUT_CART_DATA, (e) => {

  const action = e.detail.reduxAction;

  items  = action.cartData.entries.map((product, i) => {
    const category = product.url.split('/')[4] || '';

    let price = product.price;

    const productData = {
      item_name: product.name,
      item_id: product.baseProduct,
      item_category: category,
      index: i,
      quantity: product.quantity,
      price: price
    };

    return productData;
  });

  if(items.length > 0) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        items: items
      }
    });
  }
});

document.addEventListener(DETAILS_LOADED, (e) => {
  const action = e.detail.reduxAction;

  if(action.data)
  {
    const product = action.data;
    const category = product.dataLayerName.split('/')[2] || '';
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: "view_item",
      ecommerce: {
        items: [{
          item_name: product.name,
          item_id: product.productCode,
          item_category: category,
          index: 1,
          quantity: 1,
          price: product.prices
        }]
      }
    });
  }
});


